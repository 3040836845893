import Tab from "./Tab";
import styles from "../styles/TabsBar.module.css";

interface iTabsBar {
	active: string;
	navigateCallback: (name: string) => void;
}

export default function TabsBar(params: iTabsBar) {
	return (
		<div className={styles.tabs}>
			<Tab
				icon="/react_icon.svg"
				filename="home.tsx"
				name="home"
				active={params.active}
				navigateCallback={(n) => params.navigateCallback(n)}
			/>
			<Tab
				icon="/html_icon.svg"
				filename="about.html"
				name="about"
				active={params.active}
				navigateCallback={(n) => params.navigateCallback(n)}
			/>
			<Tab
				icon="/css_icon.svg"
				filename="contact.css"
				name="contact"
				active={params.active}
				navigateCallback={(n) => params.navigateCallback(n)}
			/>
			<Tab
				icon="/js_icon.svg"
				filename="projects.js"
				name="projects"
				active={params.active}
				navigateCallback={(n) => params.navigateCallback(n)}
			/>
			<Tab
				icon="/json_icon.svg"
				filename="articles.json"
				name="articles"
				active={params.active}
				navigateCallback={(n) => params.navigateCallback(n)}
			/>
			<Tab
				icon="/markdown_icon.svg"
				filename="github.md"
				name="github"
				active={params.active}
				navigateCallback={(n) => params.navigateCallback(n)}
			/>
			<Tab
				icon="/react_icon.svg"
				filename="helperFunctions.tsx"
				name="helper"
				active={params.active}
				navigateCallback={(n) => params.navigateCallback(n)}
			/>
		</div>
	);
}
