import styles from "../styles/GitHubPage.module.css";

// if you had Github followers, you could use this to display them

export default function GithubPage() {
  return (
    <>
      <div className={styles.user}>
        <img
          src={"Logo.gif"}
          alt={`Wayne Rockett's avatar`}
          className={styles.avatar}
        />
        <div className={styles.username}>Wayne Rockett</div>
        <div>3 Organisations</div>
        <div>14 Repos</div>
      </div>
      <div className={styles.container}>
        {/* {repos.map((repo) => (
          <RepoCard key={repo.id} repo={repo} />
        ))} */}
      </div>
      <div className={styles.contributions}>
        <h3>Contributions in 2023</h3>
        <img
          src={"GitHub2023.jpg"}
          alt={"GitHub contributions 2023"}
          width={1000}
          height={250}
        />
      </div>
    </>
  );
}
