import { SVGProps } from "react";
import { JSX } from "react/jsx-runtime";

const CloseIcon = (
	props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			{...props}>
			<path
				d="M19 5L5 19M5 5L19 19"
				stroke="#FFFFFF"
				strokeWidth={1.5}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CloseIcon;
