import styles from "../styles/BottomBar.module.css";
import BellIcon from "./Icons/BellIcon";
import CheckIcon from "./Icons/CheckIcon";
import ErrorIcon from "./Icons/ErrorIcon";
import NextJSIcon from "./Icons/NextJSIcon";
import SourceControlIcon from "./Icons/SourceControlIcon";
import WarningIcon from "./Icons/WarningIcon";

export default function BottomBar() {
	return (
		<footer className={styles.bottomBar}>
			<div className={styles.container}>
				<a
					href="https://github.com/itsnitinr/vscode-portfolio"
					target="_blank"
					rel="noreferrer noopener"
					className={styles.section}>
					<SourceControlIcon className={styles.icon} />
					<p>main</p>
				</a>
				<div className={styles.section}>
					<ErrorIcon className={styles.icon} />
					<p className={styles.errorText}>0</p>&nbsp;&nbsp;
					<WarningIcon className={styles.icon} />
					<p>0</p>
				</div>
			</div>
			<div className={styles.container}>
				<div className={styles.section}>
					<NextJSIcon className={styles.icon} />
					<p>Powered by Coffee and Cake</p>
				</div>
				<div className={styles.section}>
					<CheckIcon className={styles.icon} />
					<p>Prettier</p>
				</div>
				<div className={styles.section}>
					<BellIcon />
				</div>
			</div>
		</footer>
	);
}
