import styles from "../styles/TitleBar.module.css";
import CloseIcon from "./Icons/CloseIcon";
import CopyIcon from "./Icons/CopyIcon";

export default function TitleBar() {
	return (
		<section className={styles.titlebar}>
			<img
				src="/VSCode.svg"
				alt="VSCode Icon"
				height={15}
				width={15}
				className={styles.icon}
			/>
			<div className={styles.items}>
				<p>File</p>
				<p>Edit</p>
				<p>View</p>
				<p>Go</p>
				<p>Run</p>
				<p>Terminal</p>
				<p>Help</p>
			</div>
			<p className={styles.title}>Wayne Rockett</p>
			<div className={styles.windowButtons}>
				<CloseIcon />
				<CopyIcon />
			</div>
		</section>
	);
}
