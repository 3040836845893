import { useEffect, useState } from "react";
import "./Wordle.css";

const WORD_LENGTH = 5;
const MAX_ATTEMPTS = 6;

export default function WordGame() {
	const [attempts, setAttempts] = useState<string[]>([]);
	const [currentGuess, setCurrentGuess] = useState<string>("");
	const [targetWord, setTargetWord] = useState<string>("");
	const [keyboardStatuses, setKeyboardStatuses] = useState<{
		[key: string]: string;
	}>({});
	const [gameOver, setGameOver] = useState<boolean>(false);
	const [gameResult, setGameResult] = useState<"win" | "lose" | "">("");

	useEffect(() => {
		async function fetchWord() {
			try {
				const response = await fetch(
					"https://api.datamuse.com/words?sp=?????" // Query for 5-letter words
				);
				const words = await response.json();
				if (words.length > 0) {
					const randomWord =
						words[Math.floor(Math.random() * words.length)].word;
					setTargetWord(randomWord.toUpperCase());
				}
			} catch (error) {
				// do nothing
			}
		}

		fetchWord();
	}, []);

	const handleKeyPress = (key: string) => {
		if (gameOver) return;

		if (key === "Enter") {
			if (currentGuess.length === WORD_LENGTH) {
				updateKeyboardStatuses(currentGuess);
				const newAttempts = [...attempts, currentGuess];
				setAttempts(newAttempts);
				setCurrentGuess("");

				if (currentGuess === targetWord) {
					setGameOver(true);
					setGameResult("win");
				} else if (newAttempts.length === MAX_ATTEMPTS) {
					setGameOver(true);
					setGameResult("lose");
				}
			}
		} else if (key === "Backspace") {
			setCurrentGuess(currentGuess.slice(0, -1));
		} else if (currentGuess.length < WORD_LENGTH) {
			setCurrentGuess(currentGuess + key.toUpperCase());
		}
	};

	const updateKeyboardStatuses = (guess: string) => {
		const updatedStatuses = { ...keyboardStatuses };
		guess.split("").forEach((letter, index) => {
			if (targetWord[index] === letter) {
				updatedStatuses[letter] = "correct";
			} else if (
				targetWord.includes(letter) &&
				updatedStatuses[letter] !== "correct"
			) {
				updatedStatuses[letter] = "present";
			} else if (!targetWord.includes(letter)) {
				updatedStatuses[letter] = "absent";
			}
		});
		setKeyboardStatuses(updatedStatuses);
	};

	const getKeyboardLetterStatus = (letter: string) => {
		return keyboardStatuses[letter] || "";
	};

	const getLetterStatus = (letter: string, index: number) => {
		if (targetWord[index] === letter) {
			return "correct";
		} else if (targetWord.includes(letter)) {
			return "present";
		} else {
			return "absent";
		}
	};

	return (
		<div className="game-container">
			<div className="word-grid">
				{attempts.map((attempt, i) => (
					<div key={i} className="word-row">
						{attempt.split("").map((letter, j) => (
							<span
								key={j}
								className={`letter ${getLetterStatus(
									letter,
									j
								)}`}>
								{letter}
							</span>
						))}
					</div>
				))}
				{!gameOver && attempts.length < MAX_ATTEMPTS && (
					<div className="word-row">
						{Array.from(Array(WORD_LENGTH)).map((_, i) => (
							<span key={i} className="letter">
								{currentGuess[i] ?? ""}
							</span>
						))}
					</div>
				)}
			</div>
			{gameOver ? (
				<div className="game-over-message">
					{gameResult === "win" ? (
						<p>Well done! You have guessed the word correctly!</p>
					) : (
						<p>Unlucky! The word was {targetWord}.</p>
					)}
				</div>
			) : (
				<div className="keyboard">
					<div className="keyboard-row">
						{Array.from("QWERTYUIOP").map((letter) => (
							<button
								key={letter}
								onClick={() => handleKeyPress(letter)}
								className={`keyboard-button ${getKeyboardLetterStatus(
									letter
								)}`}>
								{letter}
							</button>
						))}
					</div>
					<div className="keyboard-row">
						{Array.from("ASDFGHJKL").map((letter) => (
							<button
								key={letter}
								onClick={() => handleKeyPress(letter)}
								className={`keyboard-button ${getKeyboardLetterStatus(
									letter
								)}`}>
								{letter}
							</button>
						))}
					</div>
					<div className="keyboard-row">
						<button
							onClick={() => handleKeyPress("Enter")}
							className="keyboard-button special-button">
							Enter
						</button>
						{Array.from("ZXCVBNM").map((letter) => (
							<button
								key={letter}
								onClick={() => handleKeyPress(letter)}
								className={`keyboard-button ${getKeyboardLetterStatus(
									letter
								)}`}>
								{letter}
							</button>
						))}
						<button
							onClick={() => handleKeyPress("Backspace")}
							className="keyboard-button special-button">
							Backspace
						</button>
					</div>
				</div>
			)}
		</div>
	);
}
