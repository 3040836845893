import { useState } from "react";
import ChevronRight from "./Icons/ChevronRight";
import styles from "../styles/Explorer.module.css";

const explorerItems = [
	{
		title: "home.tsx",
		name: "home",
		icon: "react_icon.svg",
	},
	{
		title: "about.html",
		name: "about",
		icon: "html_icon.svg",
	},
	{
		title: "contact.css",
		name: "contact",
		icon: "css_icon.svg",
	},
	{
		title: "projects.js",
		name: "projects",
		icon: "js_icon.svg",
	},
	{
		title: "articles.json",
		name: "articles",
		icon: "json_icon.svg",
	},
	{
		title: "github.md",
		name: "github",
		icon: "markdown_icon.svg",
	},
	{
		title: "helperFunctions.tsx",
		name: "helper",
		icon: "react_icon.svg",
	},
];

interface iExplorer {
	active: string;
	navigateCallback: (name: string) => void;
}

export default function Explorer(params: iExplorer) {
	const [portfolioOpen, setPortfolioOpen] = useState(true);

	return (
		<div className={styles.explorer}>
			<p className={styles.title}>Explorer</p>
			<div>
				<input
					type="checkbox"
					className={styles.checkbox}
					id="portfolio-checkbox"
					checked={portfolioOpen}
					onChange={() => setPortfolioOpen(!portfolioOpen)}
				/>
				<label htmlFor="portfolio-checkbox" className={styles.heading}>
					<ChevronRight
						className={styles.chevron}
						style={
							portfolioOpen ? { transform: "rotate(90deg)" } : {}
						}
					/>
					Portfolio
				</label>
				<div
					className={styles.files}
					style={
						portfolioOpen
							? { display: "block" }
							: { display: "none" }
					}>
					{explorerItems.map((item) => (
						<div onClick={() => params.navigateCallback(item.name)}>
							<div className={styles.file}>
								<img
									src={`/${item.icon}`}
									alt={item.title}
									height={18}
									width={18}
								/>{" "}
								<p>{item.title}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
