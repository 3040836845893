import styles from "../styles/ProjectsPage.module.css";
import cardStyles from "../styles/ProjectCard.module.css";

interface IHelperFunctionsPage {
	selectionCallback: (name: string) => void;
}

export default function HelperFunctionsPage(params: IHelperFunctionsPage) {
	return (
		<>
			<h3>Helper Functions</h3>
			<div className={styles.container}>
				{helperFunctions.map((helper) => (
					<div
						key={helper.id}
						className={cardStyles.card}
						onClick={() =>
							params.selectionCallback(helper.navigation)
						}>
						<div className={cardStyles.title}>{helper.title}</div>
						<img src={helper.imageSrc} alt={helper.title} />
						<p>{helper.description}</p>
						<div className={cardStyles.tags}>
							{helper.tags.map((tag) => (
								<span key={tag} className={tag}>
									{/* we need to translate some of the tags, so CSharp would become C#, VBNet would become VB.Net */}
									{tag === "CSharp"
										? "C#"
										: tag === "VBNET"
										? "VB.Net"
										: tag === "MurderMystery"
										? "Murder Mystery"
										: tag}
								</span>
							))}
						</div>
					</div>
				))}
			</div>
		</>
	);
}

type HelperFunction = {
	id: number;
	title: string;
	description: string;
	imageSrc: string;
	tags: string[];
	navigation: string;
};

const helperFunctions: HelperFunction[] = [
	{
		id: 1,
		title: "Wordle",
		description: "My version of the classic word game.",
		imageSrc: "Wordle.png",
		tags: ["TypeScript", "React"],
		navigation: "wordgame",
	},
];
