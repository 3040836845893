import { useEffect, useState } from "react";
import TitleBar from "./components/TitleBar";
import styles from "./styles/index.module.css";
import BottomBar from "./components/BottomBar";
import Sidebar from "./components/SideBar";
import Explorer from "./components/Explorer";
import TabsBar from "./components/TabsBar";
import GithubPage from "./pages/GitHubPage";
import ArticlesPage from "./pages/Articles";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ProjectsPage from "./pages/Projects";
import HomePage from "./pages/Home";
import HelperFunctionsPage from "./pages/HelperFunctions";
import WordGame from "./pages/HelperFunctions/Wordle";

// type Navigation =
//   | "home"
//   | "about"
//   | "contact"
//   | "projects"
//   | "articles"
//   | "github";

export default function App() {
	const [navigation, setNavigation] = useState<string>("home");
	const [selectedHelperFunction, setSelectedHelperFunction] =
		useState<string>();

	useEffect(() => {
		document.documentElement.setAttribute("data-theme", "");
	}, []);

	function handleNavigation(name: string) {
		if (name === "helper") {
			setSelectedHelperFunction(undefined);
		}
		setNavigation(name);
	}

	return (
		<>
			<TitleBar />
			<div className={styles.main}>
				<Sidebar
					active={navigation}
					navigateCallback={(s) => handleNavigation(s)}
				/>
				<Explorer
					active={navigation}
					navigateCallback={(s) => handleNavigation(s)}
				/>
				<div style={{ width: "100%" }}>
					<TabsBar
						active={navigation}
						navigateCallback={(s) => handleNavigation(s)}
					/>
					<main id="main-editor" className={styles.content}>
						{navigation === "home" ? <HomePage /> : null}
						{navigation === "github" ? <GithubPage /> : null}
						{navigation === "articles" ? <ArticlesPage /> : null}
						{navigation === "about" ? <About /> : null}
						{navigation === "contact" ? <Contact /> : null}
						{navigation === "projects" ? <ProjectsPage /> : null}
						{navigation === "helper" && !selectedHelperFunction ? (
							<HelperFunctionsPage
								selectionCallback={setSelectedHelperFunction}
							/>
						) : null}
						{navigation === "helper" &&
						selectedHelperFunction === "wordgame" ? (
							<WordGame />
						) : null}
					</main>
				</div>
			</div>
			<BottomBar />
		</>
	);
}
