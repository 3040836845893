import styles from "../styles/ProjectsPage.module.css";
import cardStyles from "../styles/ProjectCard.module.css";

export default function ProjectsPage() {
	return (
		<>
			<h3>My Recent or Current Projects</h3>
			<div className={styles.container}>
				{projects.map((project) => (
					<div
						key={project.id}
						className={cardStyles.card}
						onClick={() =>
							project.link && window.open(project.link, "_blank")
						}>
						<div className={cardStyles.title}>{project.title}</div>
						<img src={project.imageSrc} alt={project.title} />
						<p>{project.description}</p>
						<div className={cardStyles.tags}>
							{project.tags.map((tag) => (
								<span key={tag} className={tag}>
									{/* we need to translate some of the tags, so CSharp would become C#, VBNet would become VB.Net */}
									{tag === "CSharp"
										? "C#"
										: tag === "VBNET"
										? "VB.Net"
										: tag === "MurderMystery"
										? "Murder Mystery"
										: tag}
								</span>
							))}
						</div>
					</div>
				))}
			</div>
		</>
	);
}

type Project = {
	id: number;
	title: string;
	description: string;
	imageSrc: string;
	tags: string[];
	link?: string;
};

const projects: Project[] = [
	{
		id: 1,
		title: "Real Life",
		description:
			"Most of my time as a software developer is spent as an employee working on software for the printing industry.  I am also a scrum master for the development team.",
		imageSrc: "RealLife.png",
		tags: ["TypeScript", "CSharp", "VBNet", "Scrum"],
		link: "",
	},
	{
		id: 2,
		title: "Rockett Viewer",
		description:
			"A VS Code extension that allows you to view your code within a VS Code tab inside a mobile phone frame.  Great for testing how you application will look, or grabbing a screenshot for a presentation.  Available on the Visual Studio Marketplace.",
		imageSrc: "RockettViewer.png",
		tags: ["VSCode", "JavaScript"],
		link: "https://marketplace.visualstudio.com/items?itemName=WayneSRockett.rockett-viewer",
	},
	{
		id: 3,
		title: "Rockett Writer",
		description:
			"A desktop text editor that, well, quite frankly focuses on the features that I personally wanted.",
		imageSrc: "RockettWriter.png",
		tags: ["React", "TypeScript"],
		link: "",
	},
	{
		id: 4,
		title: "Who Dunnit Club",
		description:
			"I write and host murder mystery nights with around fifty people attending each event.  I have run Harry Potter murders, Titanic, Oscars and various other themed murders.",
		imageSrc: "WhoDunnitClub.png",
		tags: ["MurderMystery"],
		link: "https://whodunnit.club/",
	},
	{
		id: 5,
		title: "Valheim Cooking",
		description:
			"A simple website for the different food items in the game Valheim.",
		imageSrc: "ValheimCooking.png",
		tags: ["React", "TypeScript", "JavaScript", "CSS"],
		link: "https://valheim.cooking/",
	},
];
