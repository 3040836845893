import FilesIcon from "./Icons/FilesIcon";
import GithubIcon from "./Icons/GitHubIcon";
import CodeIcon from "./Icons/CodeIcon";
import PencilIcon from "./Icons/PencilIcon";
import MailIcon from "./Icons/MailIcon";
import AccountIcon from "./Icons/AccountIcon";
import SettingsIcon from "./Icons/SettingsIcon";
import styles from "../styles/SideBar.module.css";

const sidebarTopItems = [
	{
		Icon: FilesIcon,
		name: "home",
	},
	{
		Icon: GithubIcon,
		name: "github",
	},
	{
		Icon: CodeIcon,
		name: "projects",
	},
	{
		Icon: PencilIcon,
		name: "articles",
	},
	{
		Icon: MailIcon,
		name: "contact",
	},
];

const sidebarBottomItems = [
	{
		Icon: AccountIcon,
		name: "about",
	},
	{
		Icon: SettingsIcon,
		name: "settings",
	},
];

interface iSideBar {
	active: string;
	navigateCallback: (name: string) => void;
}

export default function Sidebar(params: iSideBar) {
	return (
		<aside className={styles.sidebar}>
			<div className={styles.sidebarTop}>
				{sidebarTopItems.map(({ Icon, name }) => (
					<div onClick={() => params.navigateCallback(name)}>
						<div
							className={`${styles.iconContainer} ${
								params.active === name && styles.active
							}`}>
							<Icon
								fill={
									params.active === name
										? "rgb(225, 228, 232)"
										: "rgb(106, 115, 125)"
								}
								className={styles.icon}
							/>
						</div>
					</div>
				))}
			</div>
			<div className={styles.sidebarBottom}>
				{sidebarBottomItems.map(({ Icon, name }) => (
					<div className={styles.iconContainer}>
						<div onClick={() => params.navigateCallback(name)}>
							<Icon
								fill={
									params.active === name
										? "rgb(225, 228, 232)"
										: "rgb(106, 115, 125)"
								}
								className={styles.icon}
							/>
						</div>
					</div>
				))}
			</div>
		</aside>
	);
}
