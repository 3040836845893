import styles from "../styles/Tab.module.css";

interface iTab {
  icon: string;
  filename: string;
  name: string;
  active: string;
  navigateCallback: (name: string) => void;
}

export default function Tab(params: iTab) {
  return (
    <div onClick={() => params.navigateCallback(params.name)}>
      <div
        className={`${styles.tab} ${
          params.active === params.name && styles.active
        }`}
      >
        <img src={params.icon} alt={params.filename} height={18} width={18} />
        <p>{params.filename}</p>
      </div>
    </div>
  );
}
