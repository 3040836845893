import { useEffect, useState } from "react";
// import ArticleCard from '../components/ArticleCard';
import styles from "../styles/ArticlesPage.module.css";
import cardStyles from "../styles/ArticleCard.module.css";

export default function ArticlesPage() {
  const [articles, setArticles] = useState<DevToArticle[]>([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(
          "https://dev.to/api/articles?username=diso"
        );
        const data = await response.json();
        setArticles(data);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  const hashTagToHue = (tag: string): number => {
    let hash = 0;
    for (let i = 0; i < tag.length; i++) {
      hash = tag.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash % 360;
  };

  return (
    <>
      <h3>
        My most recent articles on{" "}
        <a
          href="https://dev.to/diso"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.underline}
        >
          dev.to
        </a>
      </h3>
      <div className={styles.container}>
        {articles?.map((article) => (
          <div className={cardStyles.container}>
            <img src={article.cover_image} alt={article.title} />
            <div className={cardStyles.content}>
              <h3 className={cardStyles.title}>
                <a href={article.url} target="_blank" rel="noopener noreferrer">
                  {article.title}
                </a>
              </h3>
              <p className={cardStyles.description}>{article.description}</p>
              <p className={cardStyles.meta}>
                Published on: {article.readable_publish_date} &middot;{" "}
                {article.reading_time_minutes} min read
              </p>
              <div className={cardStyles.tags}>
                {article.tag_list.map((tag, index) => (
                  <span
                    key={index}
                    style={{
                      backgroundColor: `hsl(${hashTagToHue(tag)}, 70%, 50%)`,
                    }}
                    className={cardStyles.tag}
                  >
                    #{tag}
                  </span>
                ))}
              </div>
              <div className={cardStyles.stats}>
                <span className={cardStyles.stat}>
                  ❤️ {article.positive_reactions_count}
                </span>
                <span className={cardStyles.stat}>
                  💬 {article.comments_count}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

type DevToArticle = {
  type_of: string;
  id: number;
  title: string;
  description: string;
  readable_publish_date: string;
  slug: string;
  path: string;
  url: string;
  comments_count: number;
  public_reactions_count: number;
  collection_id: number | null;
  published_timestamp: string;
  positive_reactions_count: number;
  cover_image: string;
  social_image: string;
  canonical_url: string;
  created_at: string;
  edited_at: string | null;
  crossposted_at: string | null;
  published_at: string;
  last_comment_at: string;
  reading_time_minutes: number;
  tag_list: string[];
  tags: string;
  user: {
    name: string;
    username: string;
    twitter_username: string | null;
    github_username: string | null;
    user_id: number;
    website_url: string | null;
    profile_image: string;
    profile_image_90: string;
  };
};
