import { useState } from "react";
import emailjs from "emailjs-com";
import styles from "../styles/ContactPage.module.css";
import styles2 from "../styles/ContactPage2.module.css";

export default function Contact() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [selectedOption, setSelectedOption] = useState("");

	function submitForm(e: React.FormEvent) {
		e.preventDefault();

		if (selectedOption !== "human") {
			alert("Please select 'I am human' to proceed.");
			return;
		}

		// Set up emailjs parameters
		const templateParams = {
			name,
			email,
			subject,
			message,
		};

		// Send email
		emailjs
			.send(
				"service_ckohntr", // replace with your service ID
				"template_339j2ts", // replace with your template ID
				templateParams,
				"I8-FcgpHbHwonqQTK" // replace with your user ID
			)
			.then(
				(response) => {
					alert("Message sent successfully!");
				},
				(error) => {
					alert("Failed to send message. Please try again later.");
				}
			);
	}

	return (
		<div className={styles.container}>
			<div className={styles.contactForm}>
				<div className={styles.heading}>
					<h2>Or Fill Out This Form</h2>
					<form className={styles.form} onSubmit={submitForm}>
						{/* Form Fields */}
						<div className={styles.flex}>
							<div>
								<label htmlFor="name">Name</label>
								<input
									type="text"
									name="name"
									id="name"
									value={name}
									onChange={(e) => setName(e.target.value)}
									required
								/>
							</div>
							<div>
								<label htmlFor="email">Email</label>
								<input
									type="email"
									name="email"
									id="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className={styles.rightMargin}>
							<label htmlFor="subject">Subject</label>
							<input
								type="text"
								name="subject"
								id="subject"
								value={subject}
								onChange={(e) => setSubject(e.target.value)}
								required
							/>
						</div>
						<div className={styles.rightMargin}>
							<label htmlFor="message">Message</label>
							<textarea
								name="message"
								id="message"
								rows={5}
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								required></textarea>
						</div>
						<div>
							<label>
								Please select "I am human"
								<select
									name="human-check"
									value={selectedOption}
									onChange={(e) =>
										setSelectedOption(e.target.value)
									}
									required>
									<option value="">Select an option</option>
									<option value="human">I am human</option>
									<option value="alien">I am alien</option>
									<option value="robot">I am robot</option>
								</select>
							</label>
						</div>
						<div style={{ justifySelf: "end" }}>
							<button type="submit">Submit</button>
						</div>
					</form>
				</div>
			</div>
			{/* Social Links */}
			<div className={styles2.code}>
				<h2 className={styles.heading}>Or Connect Via Social Media</h2>
				<ul>
					<li>
						Website:{" "}
						<a
							className={styles2.code}
							href="http://waynerockett.com">
							This Website
						</a>
					</li>
					<li>
						Email:{" "}
						<a href="mailto:wayne@waynerockett.com">Mail To Link</a>
					</li>
					<li>
						GitHub:{" "}
						<a href="https://github.com/WayneRockett">Github</a>
					</li>
					<li>
						LinkedIn:{" "}
						<a href="https://www.linkedin.com/in/waynerockett/">
							LinkedIn
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
}
