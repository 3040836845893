import { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import styles from "../styles/About.module.css";

export default function About() {
  const [aboutContent, setAboutContent] = useState<string>("");

  useEffect(() => {
    const fetchAboutFile = async () => {
      try {
        const response = await fetch(
          "https://api.github.com/repos/WayneRockett/WayneRockett/contents/README.md"
        );

        if (!response.ok) {
          throw new Error("Failed to fetch file from GitHub");
        }

        const data = await response.json();
        const contentBase64 = data.content; // File content in base64 format
        const decodedContent = decodeURIComponent(escape(atob(contentBase64))); // Properly decode base64 to string

        // Preprocess the content to replace `---` with a single line break
        let preprocessedContent = decodedContent.replace(/---/g, "<br>");

        // Remove the "Support Me" section
        const supportMeRegex = /### Support Me[\s\S]*?(?=###|$)/g;
        preprocessedContent = preprocessedContent.replace(supportMeRegex, "");

        setAboutContent(preprocessedContent);
      } catch (error) {
        console.error("Error fetching about file:", error);
      }
    };

    fetchAboutFile();
  }, []);

  return (
    <div className="about-section">
      <h2>About Me</h2>
      <Markdown
        options={{
          forceBlock: true,
          overrides: {
            img: {
              component: "img",
              props: {
                style: {
                  maxWidth: "100%",
                  height: "auto",
                },
              },
            },
            p: {
              props: {
                style: {
                  marginBottom: "1em",
                },
              },
            },
            h2: {
              props: {
                className: styles.headings,
              },
            },
            h3: {
              props: {
                className: styles.title,
              },
            },
          },
        }}
      >
        {aboutContent}
      </Markdown>
    </div>
  );
}
